<template>
    <div>
        <actual></actual>
        <today></today>
        <week></week>
        <month></month>
    </div>
</template>

<script>
import month from './component/month'
import week from './component/week'
import today from './component/today'
import actual from './component/actual'
export default {
    components: { actual , today, week, month},
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>