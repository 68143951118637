<template>

  <a-card :bordered="false">
    <template slot="title">
      <a-row justify="space-between" type="flex">
        <a-col :span="6">
          <a-space>
            <span style="font-weight: bolder; font-size: 20px;">本周采集情况</span>
            <a-week-picker v-model="query.time" @change="handleQueryTimeChange"/>
          </a-space>
        </a-col>
      </a-row>
    </template>
    <a-spin :spinning="loading">
        <a-row :gutter="12">
            <a-col :span="12">
                <a-statistic  style="margin-right: 50px" suffix="KG" title="厨余垃圾" :value="kitchenData.kitchenWaste"/>
                <a-space >
                    <span :style="{color: Number(compareLastWeek.compareLastWeekPercentageKitchen) > 0 ? 'green' : 'red'}">
                    <a-icon
                        :type="Number(compareLastWeek.compareLastWeekPercentageKitchen) > 0 ? 'caret-up' : 'caret-down'"/>
                    <span>{{ Number(compareLastWeek.compareLastWeekPercentageKitchen) }}%</span>
                    </span>
                    <span style="color: grey; font-size: small;">同比上周</span>
                </a-space>
                <div  style="height: 21px;"></div>
                <div id="weekCollectKitchenChart" style="height: 200px;"></div>
            </a-col>
            <a-col :span="12">
                <a-statistic  style="margin-right: 50px" suffix="KG" title="其他垃圾" :value="otherData.otherWaste"/>
                    <a-space >
                    <span :style="{color: Number(compareLastWeek.compareLastWeekPercentageOther) > 0 ? 'green' : 'red'}">
                    <a-icon
                        :type="Number(compareLastWeek.compareLastWeekPercentageOther) > 0 ? 'caret-up' : 'caret-down'"/>
                    <span>{{ Number(compareLastWeek.compareLastWeekPercentageOther) }}%</span>
                    </span>
                    <span style="color: grey; font-size: small;">同比上周</span>
                </a-space>
                <div  style="height: 21px;"></div>
                <div id="weekCollectOtherChart" style="height: 200px;"></div>
            </a-col>
        </a-row>
    </a-spin>
  </a-card>
</template>

<script>
import { queryStreetWeekAnalyzeApi } from '@/api/street'
import { Area } from '@antv/g2plot';
import moment from 'moment'
export default {
    data() {
        return{
            query: {
                time: moment(),
                timeStatus: 'week'
            },
            kitchenChart: null,
            otherChart: null,
            loading: true,
            kitchenData: {},
            otherData: {},
            compareLastWeek: '',
            weekList: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        }
    },
    watch: {
    },
    mounted() {
        this. queryStreetWeekAnalyze()
    },
    methods: {
        handleQueryTimeChange(e) {
            this.query.time = e
            this.queryStreetWeekAnalyze()
        },
         queryStreetWeekAnalyze() {
            this.loading = true
            queryStreetWeekAnalyzeApi({...this.query}, this.$route.query.moduleCode).then(res => {
                let data = res.result
                this.kitchenData = {
                    kitchenWaste: data.kitchenWaste,
                    kitchenList: data.detailedData
                }
                this.otherData = {
                    otherWaste: data.other,
                    otherList: data.detailedData
                }
                this.compareLastWeek = {
                    compareLastWeekPercentageKitchen: data.totalKitchenWasteLastPercentage,
                    compareLastWeekPercentageOther: data.totalOtherLastPercentage,
                }
                this.drawKitchenChart()
                this.drawOtherChart()
                this.loading = false
            })
        },
        drawKitchenChart() {
            if(this.kitchenChart) {
                this.kitchenChart.update({
                    data: this.kitchenData.kitchenList
                })
                return
            }
            this.kitchenChart = new Area('weekCollectKitchenChart', {
                data: this.kitchenData.kitchenList,
                xField: 'time',
                color: '#808080',
                yField: 'kitchenWaste',
                xAxis: {
                    range: [0, 1],
                    label: {
                        formatter: (text) => {
                        return this.weekList[parseInt(moment(text).weekday())]
                        }
                    },
                },
            })
            this.kitchenChart.render();
        },
        drawOtherChart() {
            if(this.otherChart) {
                this.otherChart.update({
                    data:this.otherData.otherList
                })
                return
            }
            this.otherChart = new Area('weekCollectOtherChart', {
                data: this.otherData.otherList,
                xField: 'time',
                yField: 'other',
                color: '#3E92F1',
                smooth: true,
                xAxis: {
                    range: [0, 1],
                    label: {
                        formatter: (text) => {
                        return this.weekList[parseInt(moment(text).weekday())]
                        }
                    },
                },
            })
            this.otherChart.render();

        }
    }
}
</script>
<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>